import { render, staticRenderFns } from "./SingleColumnSet.vue?vue&type=template&id=c6471acc&scoped=true&"
import script from "./SingleColumnSet.vue?vue&type=script&lang=js&"
export * from "./SingleColumnSet.vue?vue&type=script&lang=js&"
import style0 from "./SingleColumnSet.vue?vue&type=style&index=0&id=c6471acc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6471acc",
  null
  
)

export default component.exports