<template>
  <div class="file-upload-dialog full-page-dialog">
    <div class="dialog-container">
      <transition
        name="fade"
        mode="out-in"
      >
        <template v-if="isShowRemindingDialog">
          <div class="reminding-dialog">
            <p>{{ $t('editing.dataBuildingReminding') }}</p>
            <button
              class="btn btn-default"
              @click="isShowRemindingDialog = false; $emit('exit')"
            >
              {{ $t('button.confirm') }}
            </button>
          </div>
        </template>
        <choose-file-type
          v-else-if="currentImportType === null"
          @exit="$emit('exit')"
          @select="currentImportType = $event"
        />
        <import-flow
          v-else
          :type="currentImportType"
          @done="isShowRemindingDialog = true; $emit('done')"
          @exit="!isShowRemindingDialog && $emit('exit')"
        />
      </transition>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onUnmounted } from '@vue/composition-api'
import { useMapMutations } from '@/utils/composable/vuex'
import { ImportType } from '../constants'
import ChooseFileType from './ChooseFileType.vue'
import ImportFlow from './ImportFlow.vue'

export default defineComponent({
  name: 'CreateDataFrameDialog',
  components: {
    ChooseFileType,
    ImportFlow
  },
  setup () {
    const currentImportType = ref(null)
    const isShowRemindingDialog = ref(false)

    // Reset store
    const {
      changeCurrentTableIndex,
      clearEtlTableList,
      updateUploadFileList
    } = useMapMutations('dataManagement', ['clearEtlTableList', 'changeCurrentTableIndex', 'updateUploadFileList'])
    onUnmounted(() => {
      if (currentImportType.value === ImportType.LOCAL) {
        updateUploadFileList([])
      }
      clearEtlTableList()
      changeCurrentTableIndex(0)
    })

    return {
      currentImportType,
      isShowRemindingDialog
    }
  }
})
</script>

<style lang="scss" scoped>
.reminding-dialog {
  margin-top: 40vh;
  text-align: center;
}
</style>
