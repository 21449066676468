import { render, staticRenderFns } from "./EditColumnSetDialog.vue?vue&type=template&id=061b4c44&scoped=true&"
import script from "./EditColumnSetDialog.vue?vue&type=script&lang=js&"
export * from "./EditColumnSetDialog.vue?vue&type=script&lang=js&"
import style0 from "./EditColumnSetDialog.vue?vue&type=style&index=0&id=061b4c44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061b4c44",
  null
  
)

export default component.exports