<template>
  <div class="edit-file-data-update-dialog full-page-dialog">
    <div class="dialog-container">
      <choose-file-data-update-mode
        v-if="step === 1"
        :step="1"
        :title="$t('fileDataUpdate.updateData')"
        :data-frame-info="dataFrameInfo"
        :process-text="processText"
        @next="chooseMode"
        @close="cancelFileUpdate"
      />
      <data-update-file-upload
        v-if="step === 2"
        :step="step"
        :data-frame-info="dataFrameInfo"
        :process-text="processText"
        @prev="prevStep"
        @next="nextStep"
        @close="cancelFileUpdate"
      />
      <data-update-file-upload-status
        v-if="step === 3"
        :step="step"
        :data-frame-info="dataFrameInfo"
        :process-text="processText"
        :update-mode="updateMode"
        @prev="prevStep"
        @next="nextStep"
        @close="cancelFileUpdate"
      />
      <confirm-page
        v-if="step === 4"
        :content="$t('fileDataUpdate.dataUpdatingReminding')"
        @next="uploadFinish"
      />
    </div>
  </div>
</template>

<script>
import ChooseFileDataUpdateMode from './localFileUpload/dataUpdate/ChooseFileDataUpdateMode.vue'
import DataUpdateFileUpload from './localFileUpload/dataUpdate/DataUpdateFileUpload.vue'
import DataUpdateFileUploadStatus from './localFileUpload/dataUpdate/DataUpdateFileUploadStatus.vue'
import ConfirmPage from './localFileUpload/fileUpload/ConfirmPage.vue'

export default {
  name: 'EditFileDataUploadDialog',
  components: {
    ChooseFileDataUpdateMode,
    DataUpdateFileUpload,
    DataUpdateFileUploadStatus,
    ConfirmPage
  },
  props: {
    dataFrameInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      step: 1,
      updateMode: '',
      isProcessing: false,
      processText: [
        this.$t('fileDataUpdate.processStep1'),
        this.$t('fileDataUpdate.processStep2'),
        this.$t('fileDataUpdate.processStep3')
      ]
    }
  },
  destroyed () {
    this.$store.commit('dataManagement/updateUploadFileList', [])
  },
  methods: {
    chooseMode (updateMode) {
      this.updateMode = updateMode
      this.nextStep()
    },
    cancelFileUpdate () {
      this.$emit('close')
    },
    nextStep () {
      this.step += 1
    },
    prevStep () {
      this.step -= 1
    },
    uploadFinish () {
      this.$store.commit('dataManagement/updateFileUploadSuccess', true)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-file-data-update-dialog {
  .setting-block {
    background-color: rgba(50, 58, 58, 0.95);
    border-radius: 5px;
    margin-bottom: 12px;
    padding: 24px 14%;
    position: relative;

    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 16px;
    }
  }
}
</style>
