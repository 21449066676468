<template>
  <div class="choose-file-type">
    <div class="dialog-title">
      {{ $t('editing.newData') }}
    </div>
    <div class="dialog-body">
      <div class="input-block-container">
        <div class="choose-type-container">
          <div
            v-for="(item, index) in importTypeList"
            :key="`import-type-${index}`"
            :class="{ disabled: !item.available }"
            class="single-type-block"
            @click="item.available && $emit('select', item.type)"
          >
            <svg-icon
              class="check-icon"
              icon-class="check-circle"
            />
            <div class="single-type-content">
              <svg-icon
                :icon-class="item.icon"
                class="icon"
              />
              <div class="type-title">
                {{ item.title }}
              </div>
              <div
                class="type-content"
                v-html="item.description"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
      <div class="dialog-button-block">
        <button
          class="btn btn-outline"
          @click="$emit('exit')"
        >
          {{ $t('button.cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useMapState } from '@/utils/composable/vuex'
import { useI18n } from '@/utils/composable/i18n'
import { ImportType, ImportTypePermissionMap } from '../constants'
import SySelect from '@/components/select/SySelect'
import InputBlock from '@/components/InputBlock'

export default defineComponent({
  name: 'ChooseFileType',
  components: {
    SySelect,
    InputBlock
  },
  setup () {
    const { t } = useI18n()
    const { permission } = useMapState('userManagement', ['permission'])

    /**
     * @param {keyof ImportType} importType
     */
    function validatePermission (importType) {
      const permissionKey = ImportTypePermissionMap[importType]
      return permission.value.includes(permissionKey)
    }

    const importTypeList = computed(() => [
      {
        type: ImportType.LOCAL,
        icon: 'upload',
        title: t('editing.localUpload'),
        description: t('editing.localUploadDescription'),
        available: validatePermission(ImportType.LOCAL)
      },
      {
        type: ImportType.REMOTE_DB,
        icon: 'db-connection',
        title: t('editing.remoteDbConnection'),
        description: t('editing.remoteDbConnectionDescription'),
        available: validatePermission(ImportType.REMOTE_DB)
      },
      {
        type: ImportType.REMOTE_SFTP,
        icon: 'sftp-connection',
        title: t('editing.remoteSftpConnection'),
        description: t('editing.remoteSftpConnectionDescription'),
        available: validatePermission(ImportType.REMOTE_SFTP)
      }
    ])

    return {
      importTypeList
    }
  }
})
</script>
<style lang="scss" scoped>
.choose-file-type {
  .dialog-body {
    background: rgba(50, 58, 58, 0.95);
    border-radius: 5px;
    margin-bottom: 16px;
  }

  .input-block-container {
    display: flex;
    flex-direction: column;
    height: 308px;
    justify-content: center;
    margin: 0 auto;
    padding: 24px;
    width: 100%;

    .choose-type-container {
      display: flex;
      height: 100%;
      justify-content: space-between;

      .single-type-block {
        align-items: center;
        border: 2px solid rgba(72, 84, 84, 0.95);
        border-radius: 12px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        padding: 20px;
        position: relative;
        width: 50%;

        &.disabled {
          cursor: not-allowed;
          opacity: 0.3;

          &:hover {
            border: 2px solid rgba(72, 84, 84, 0.95);
          }
        }

        &:hover {
          border: 2px solid #2ad2e2;
        }

        .icon {
          font-size: 70px;
        }

        .check-icon {
          color: #2ad2e2;
          display: none;
          font-size: 30px;
          position: absolute;
          right: 15px;
          top: 15px;
        }

        .type-title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 12px;
        }

        .type-content {
          color: #ccc;
          font-size: 14px;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }
      }

      .single-type-content {
        text-align: center;
      }
    }
  }
}
</style>
